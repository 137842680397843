/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faFacebookF, faYoutube, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'


import Header from "./header"

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            <div>
                <main>{children}</main>
                <footer>
                    <h1>Get in Touch<span className="text-red">.</span></h1>
                    <h4>Contact Us</h4>
                    <p>5718 3rd St,<br />Katy, TX 77493<br />(832) 464-4619</p>
                    <h4>On Social</h4>
                    <div className="social-links">
                        <a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/" className="">
                            <span className="icon"><FontAwesomeIcon icon={faFacebook} /></span>
                        </a>
                        <a target="_blank" rel="noreferrer noopener" href="https://twitter.com/" className="">
                            <span className="icon"><FontAwesomeIcon icon={faTwitter} /></span>
                        </a>
                        <a target="_blank" rel="noreferrer noopener" href="https://www.youtube.com/" className="">
                            <span className="icon"><FontAwesomeIcon icon={faYoutube} /></span>
                        </a>
                        <a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/" className="">
                            <span className="icon"><FontAwesomeIcon icon={faLinkedin} /></span>
                        </a>
                    </div>
                    <div className="copy">
                        © {new Date().getFullYear()} Honor 4 Life
                    </div>
                </footer>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
